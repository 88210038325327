import CopilotBadge from '@github-ui/copilot-chat/components/CopilotBadge'
import {sendEvent} from '@github-ui/hydro-analytics'
import {commitSetLabelsForLabelableMutation} from '@github-ui/item-picker/commitSetLabelsForLabelableMutation'
import {ERRORS} from '@github-ui/item-picker/Errors'
import type {LabelPickerLabel$data} from '@github-ui/item-picker/LabelPickerLabel.graphql'
import {LabelsList} from '@github-ui/labels-list'
// eslint-disable-next-line no-restricted-imports
import {useToastContext} from '@github-ui/toast/ToastContext'
import {CheckIcon, SyncIcon, XIcon} from '@primer/octicons-react'
import {Button, Text} from '@primer/react'
import {Suspense, useCallback, useEffect, useTransition} from 'react'
import {useRelayEnvironment} from 'react-relay'
import {LABELS} from '../../constants/labels'
import {TEST_IDS} from '../../constants/test-ids'
import {useCopilot} from '../../utils/useCopilot'
import {Section} from '../Section'
import {SectionHeader} from '../SectionHeader'

export type CopilotLabelsSectionProps = {
  issueBody: string
  issueId: string
  issueTitle: string
  onIssueUpdate?: () => void
  repositoryName?: string
  repositoryOwner?: string
  sectionHeader: JSX.Element
  setShouldShowCopilotComponent: (shouldShowCopilotComponent: boolean) => void
}

export function CopilotLabelsSection({
  issueBody,
  issueId,
  issueTitle,
  onIssueUpdate,
  repositoryName,
  repositoryOwner,
  sectionHeader,
  setShouldShowCopilotComponent,
}: CopilotLabelsSectionProps) {
  const {getSuggestions, suggestedLabels, labelsStatus} = useCopilot()
  const {addToast} = useToastContext()
  const environment = useRelayEnvironment()
  // Add isPending state to inform users when a transition is happening
  const [isPending, startContentTransition] = useTransition()

  const onSelectionChanged = useCallback(
    (selectedLabels: LabelPickerLabel$data[]) => {
      commitSetLabelsForLabelableMutation({
        environment,
        input: {labelableId: issueId, labels: selectedLabels, labelableTypeName: 'Issue'},
        onError: () =>
          // eslint-disable-next-line @github-ui/dotcom-primer/toast-migration
          addToast({
            type: 'error',
            message: ERRORS.couldNotUpdateLabels,
          }),
        onCompleted: onIssueUpdate,
      })
    },
    [addToast, environment, issueId, onIssueUpdate],
  )

  useEffect(() => {
    // Use the more specific startContentTransition to defer updates
    startContentTransition(() => {
      getSuggestions({issueId, environment, issueTitle, issueBody, repositoryName, repositoryOwner, type: 'labels'})
    })
  }, [
    environment,
    getSuggestions,
    issueBody,
    issueId,
    issueTitle,
    repositoryName,
    repositoryOwner,
    startContentTransition,
  ])

  const onConfirmCopilotLabels = useCallback(() => {
    startContentTransition(() => {
      onSelectionChanged(suggestedLabels)
      setShouldShowCopilotComponent(false)
      sendEvent('copilot_suggested_labels.confirm', {
        labels: suggestedLabels.map(label => label.name).join(','),
        issueId,
      })
    })
  }, [suggestedLabels, issueId, onSelectionChanged, setShouldShowCopilotComponent, startContentTransition])

  const onCancelCopilotLabels = useCallback(() => {
    startContentTransition(() => {
      setShouldShowCopilotComponent(false)
      sendEvent('copilot_suggested_labels.dismiss', {
        labels: suggestedLabels.map(label => label.name).join(','),
        issueId,
      })
    })
  }, [suggestedLabels, issueId, setShouldShowCopilotComponent, startContentTransition])

  const onErrorRetry = useCallback(() => {
    startContentTransition(() => {
      getSuggestions({issueId, environment, issueTitle, issueBody, repositoryName, repositoryOwner, type: 'labels'})
      sendEvent('copilot_suggested_labels_error.retry')
    })
  }, [environment, getSuggestions, issueBody, issueId, issueTitle, repositoryName, repositoryOwner])

  const onErrorCancel = useCallback(() => {
    startContentTransition(() => {
      setShouldShowCopilotComponent(false)
      sendEvent('copilot_suggested_labels_error.cancel')
    })
  }, [setShouldShowCopilotComponent, startContentTransition])

  // Use the Suspense boundary appropriately
  return (
    <Suspense fallback={<CopilotLabelsSectionLoading />}>
      <Section sectionHeader={sectionHeader}>
        {labelsStatus === 'loading' && (
          <span className="d-flex flex-items-center pl-2 py-1" aria-live="polite" aria-busy={isPending}>
            <CopilotBadge isLoading />
            <Text sx={{pl: 2, fontSize: 0, color: 'fg.muted'}}>Copilot is thinking...</Text>
          </span>
        )}
        {labelsStatus === 'success' && (
          <CopilotLabelsSectionSuccess
            labels={suggestedLabels}
            onConfirm={onConfirmCopilotLabels}
            onCancel={onCancelCopilotLabels}
            setShouldShowCopilotComponent={setShouldShowCopilotComponent}
            startContentTransition={startContentTransition}
          />
        )}
        {labelsStatus === 'error' && <CopilotLabelsSectionError onRetry={onErrorRetry} onCancel={onErrorCancel} />}
      </Section>
    </Suspense>
  )
}

function CopilotLabelsSectionLoading() {
  return (
    <Section sectionHeader={<SectionHeader title={LABELS.sectionTitles.labels} readonly />}>
      <span className="d-flex flex-items-center pl-2 py-1" aria-busy="true" aria-live="polite">
        <CopilotBadge isLoading />
        <Text sx={{pl: 2, fontSize: 0, color: 'fg.muted'}}>Copilot is thinking...</Text>
      </span>
    </Section>
  )
}

function CopilotLabelsSectionSuccess({
  labels,
  onConfirm,
  onCancel,
  setShouldShowCopilotComponent,
  startContentTransition,
}: {
  labels: LabelPickerLabel$data[]
  onConfirm: () => void
  onCancel: () => void
  setShouldShowCopilotComponent: (shouldShow: boolean) => void
  startContentTransition: (callback: () => void) => void
}) {
  useEffect(() => {
    if (labels.length === 0) {
      startContentTransition(() => {
        setShouldShowCopilotComponent(false)
      })
    }
  }, [labels, setShouldShowCopilotComponent, startContentTransition])

  // Early return with null if no labels without immediately calling setState
  if (labels.length === 0) {
    return null
  }

  return (
    <div className="d-flex flex-items-center flex-justify-between pl-0 py-1">
      <LabelsList sx={{pt: 0, pb: 0}} labels={labels} testId={TEST_IDS.issueLabels} />
      <span className="d-flex flex-items-center pl-1">
        <Button variant="invisible" size="small" icon={CheckIcon} onClick={onConfirm} aria-label="Confirm" />
        <Button variant="invisible" size="small" icon={XIcon} onClick={onCancel} aria-label="Cancel" />
      </span>
    </div>
  )
}

function CopilotLabelsSectionError({onRetry, onCancel}: {onRetry: () => void; onCancel: () => void}) {
  return (
    <span className="d-flex flex-items-center flex-justify-between pl-1">
      <span className="d-flex flex-items-center pl-1 py-1">
        <CopilotBadge isError />
        <Text sx={{pl: 2, fontSize: 0}}>Error. Please try again.</Text>
      </span>
      <span className="d-flex flex-items-center pl-1">
        <Button variant="invisible" size="small" icon={SyncIcon} onClick={onRetry} aria-label="Retry" />
        <Button variant="invisible" size="small" icon={XIcon} onClick={onCancel} aria-label="Cancel" />
      </span>
    </span>
  )
}

try{ CopilotLabelsSection.displayName ||= 'CopilotLabelsSection' } catch {}
try{ CopilotLabelsSectionLoading.displayName ||= 'CopilotLabelsSectionLoading' } catch {}
try{ CopilotLabelsSectionSuccess.displayName ||= 'CopilotLabelsSectionSuccess' } catch {}
try{ CopilotLabelsSectionError.displayName ||= 'CopilotLabelsSectionError' } catch {}