import type {Assignee} from '@github-ui/item-picker/AssigneePicker'
import {copilotHovercardPath, userHovercardPath} from '@github-ui/paths'
import {ActionList, Box, type BetterSystemStyleObject} from '@primer/react'
import {AssigneeVisual} from './AssigneeVisual'
import {isCopilot} from './copilot-user'

export type AssigneesProps = {
  assignees: Assignee[]
  sx?: BetterSystemStyleObject
  testId?: string
}

export function Assignees({assignees, testId}: AssigneesProps) {
  return (
    <ActionList sx={{py: 0}} variant={'full'}>
      {assignees.sort(sortByLogin).map(assignee => {
        const {login, avatarUrl, hovercardUrl, hovercardType} = displayData(assignee)

        return (
          <ActionList.LinkItem
            key={assignee.id}
            href={`/${login}`}
            target="_blank"
            data-hovercard-url={hovercardUrl}
            data-hovercard-type={hovercardType}
          >
            <ActionList.LeadingVisual>
              <AssigneeVisual login={login} id={assignee.id} avatarUrl={avatarUrl} />
            </ActionList.LeadingVisual>
            <Box sx={{mx: 0, width: '100%', fontSize: 0, fontWeight: '600'}} data-testid={testId}>
              {login}
            </Box>
          </ActionList.LinkItem>
        )
      })}
    </ActionList>
  )
}

function sortByLogin(a: Assignee, b: Assignee) {
  return a.login === b.login ? 0 : a.login > b.login ? 1 : -1
}

function displayData(assignee: Assignee) {
  if (isCopilot(assignee.login)) {
    return {
      login: 'Copilot',
      avatarUrl: assignee.avatarUrl,
      hovercardUrl: copilotHovercardPath({bot_slug: assignee.login}),
      hovercardType: 'copilot',
    }
  } else {
    return {
      login: assignee.login,
      avatarUrl: assignee.avatarUrl,
      hovercardUrl: userHovercardPath({owner: assignee.login}),
      hovercardType: 'user',
    }
  }
}

try{ Assignees.displayName ||= 'Assignees' } catch {}