import {Assignees} from '@github-ui/assignees'
import {ERRORS} from '@github-ui/item-picker/Errors'
import type {AssigneePickerAssignee$key} from '@github-ui/item-picker/AssigneePicker.graphql'

import {commitUpdateIssueAssigneesMutation} from '@github-ui/item-picker/updateIssueAssigneesMutation'
import {commitUpdateIssueAssigneesMutationV2} from '@github-ui/item-picker/commitUpdateIssueAssigneesMutationV2'
import {commitAssignAppToIssueMutation} from '@github-ui/item-picker/commitAssignAppToIssueMutation'
import type {Assignee} from '@github-ui/item-picker/AssigneePicker'
import {AssigneeFragment, AssigneePicker, AssigneeRepositoryPicker} from '@github-ui/item-picker/AssigneePicker'
import {AssigneePickerV2, AssigneeRepositoryPickerV2} from '@github-ui/item-picker/AssigneePickerV2'
import {useViewer} from '@github-ui/item-picker/useViewer'
// eslint-disable-next-line no-restricted-imports
import {useToastContext} from '@github-ui/toast/ToastContext'
import {useMemo, useCallback, type RefObject} from 'react'
import {readInlineData, useRelayEnvironment} from 'react-relay'
import {graphql, useFragment} from 'react-relay/hooks'

import {LABELS} from '../../constants/labels'
import {ReadonlySectionHeader} from '../ReadonlySectionHeader'
import {SectionHeader} from '../SectionHeader'
import {Section} from '../Section'
import {Button, type SxProp} from '@primer/react'
import type {AssigneesSectionFragment$key} from './__generated__/AssigneesSectionFragment.graphql'
import type {AssigneesSectionAssignees$key} from './__generated__/AssigneesSectionAssignees.graphql'
import {TEST_IDS} from '../../constants/test-ids'
import type {AssigneesSectionLazyFragment$key} from './__generated__/AssigneesSectionLazyFragment.graphql'
import {noop} from '@github-ui/noop'
import {isCopilot} from '@github-ui/assignees/copilot-user'
import {useFeatureFlags} from '@github-ui/react-core/use-feature-flag'

const ReadonlyAssigneesSectionHeader = () => <ReadonlySectionHeader title={LABELS.sectionTitles.assignees} />

const AssigneesSectionLazyFragment = graphql`
  fragment AssigneesSectionLazyFragment on Issue @argumentDefinitions(useAssigneePickerV2: {type: "Boolean!"}) {
    participants(first: 10) {
      nodes {
        ...AssigneePickerAssignee
      }
    }
    # TODO issues_react_assignee_picker_v2 remove this node entirely once the feature flag is promoted
    repository {
      installedAppInstallations(isAssignable: true, first: 10) @skip(if: $useAssigneePickerV2) {
        edges {
          node {
            app {
              id
              # eslint-disable-next-line relay/unused-fields
              slug
              name
            }
          }
        }
      }
    }
    # TODO issues_react_assignee_picker_v2 use suggestedActors here
    # eslint-disable-next-line relay/unused-fields
    suggestedAssignees(first: 1) @include(if: $useAssigneePickerV2) {
      edges {
        node {
          id
        }
      }
    }
  }
`

type AssigneesSectionProps = {
  sectionHeader: JSX.Element
  onSelfAssignClick: () => void
  assignees: Assignee[]
  readonly: boolean
} & SxProp
const AssigneesSection = ({sectionHeader, onSelfAssignClick, assignees, readonly}: AssigneesSectionProps) => {
  const emptyHeader = !readonly ? (
    <>
      {LABELS.emptySections.assignees(true)}
      <Button
        variant="link"
        onClick={onSelfAssignClick}
        sx={{color: 'fg.accent', fontWeight: 400, cursor: 'pointer', '&:hover': {textDecoration: 'none'}}}
      >
        {LABELS.emptySections.selfAssign}
      </Button>
    </>
  ) : (
    LABELS.emptySections.assignees(false)
  )

  return (
    <Section sectionHeader={sectionHeader} emptyText={assignees.length > 0 ? undefined : emptyHeader}>
      <Assignees assignees={assignees} testId={TEST_IDS.assignees} />
    </Section>
  )
}

export type CreateIssueAssigneesSectionProps = {
  repo: string
  owner: string
  readonly: boolean
  assignees: Assignee[]
  maximumAssignees?: number
  onSelectionChange: (value: Assignee[]) => void
  insidePortal: boolean
  shortcutEnabled: boolean
} & SxProp
export function CreateIssueAssigneesSection({
  repo,
  owner,
  readonly,
  assignees,
  onSelectionChange,
  maximumAssignees,
  sx,
  ...sharedConfigProps
}: CreateIssueAssigneesSectionProps) {
  const viewer = useViewer()
  const {issues_react_assignee_picker_v2} = useFeatureFlags()

  const pickerProps = {
    repo,
    owner,
    readonly,
    assigneeTokens: [],
    assignees,
    onSelectionChange,
    anchorElement: (anchorProps: React.HTMLAttributes<HTMLElement>, ref: RefObject<HTMLButtonElement>) => (
      <SectionHeader title={LABELS.sectionTitles.assignees} buttonProps={anchorProps} ref={ref} />
    ),
    maximumAssignees,
    ...sharedConfigProps,
  }

  const sectionHeader = readonly ? (
    <ReadonlyAssigneesSectionHeader />
  ) : issues_react_assignee_picker_v2 ? (
    <AssigneeRepositoryPickerV2 {...pickerProps} />
  ) : (
    <AssigneeRepositoryPicker {...pickerProps} />
  )

  return (
    <AssigneesSection
      sectionHeader={sectionHeader}
      onSelfAssignClick={() => onSelectionChange(viewer ? [viewer] : [])}
      assignees={assignees}
      readonly={readonly}
      sx={sx}
    />
  )
}

export const assigneesSectionAssignees = graphql`
  fragment AssigneesSectionAssignees on Issue {
    assignees(first: 20) {
      nodes {
        ...AssigneePickerAssignee
      }
    }
  }
`

export const assigneesSectionFragment = graphql`
  fragment AssigneesSectionFragment on Issue {
    id
    number
    repository {
      name
      owner {
        login
      }
      isArchived
      planFeatures {
        maximumAssignees
      }
    }
    ...AssigneesSectionAssignees
    # eslint-disable-next-line relay/unused-fields
    viewerCanUpdateNext
    viewerCanAssign
  }
`

export type EditIssueAssigneesSectionProps = {
  issue: AssigneesSectionFragment$key
  viewer: AssigneePickerAssignee$key | null
  lazyKey?: AssigneesSectionLazyFragment$key
  onIssueUpdate?: () => void
  singleKeyShortcutsEnabled: boolean
  insideSidePanel?: boolean
}
export function EditIssueAssigneesSection({
  issue,
  viewer,
  lazyKey,
  onIssueUpdate,
  singleKeyShortcutsEnabled,
  insideSidePanel,
}: EditIssueAssigneesSectionProps) {
  const data = useFragment(assigneesSectionFragment, issue)
  const {
    repository: {
      owner: {login: owner},
      name: repo,
      isArchived: isRepositoryArchived,
      planFeatures,
    },
    number,
  } = data

  const maximumAssignees = planFeatures?.maximumAssignees || 10

  const assigneesData = useFragment<AssigneesSectionAssignees$key>(assigneesSectionAssignees, data)
  // eslint-disable-next-line no-restricted-syntax
  const viewerData = readInlineData(AssigneeFragment, viewer)

  const {id: issueId, viewerCanAssign} = data

  const lazyData = useFragment(AssigneesSectionLazyFragment, lazyKey)

  const participants = (lazyData?.participants?.nodes || []).flatMap(participant =>
    participant
      ? // eslint-disable-next-line no-restricted-syntax
        [readInlineData<AssigneePickerAssignee$key>(AssigneeFragment, participant)]
      : [],
  )

  const assignableApps = (lazyData?.repository?.installedAppInstallations?.edges || []).flatMap(edge =>
    edge?.node?.app ? [edge.node.app] : [],
  )

  const assignees = useMemo(() => {
    return (assigneesData.assignees?.nodes || []).flatMap(assignee =>
      // eslint-disable-next-line no-restricted-syntax
      assignee ? [readInlineData<AssigneePickerAssignee$key>(AssigneeFragment, assignee)] : [],
    )
  }, [assigneesData])

  const {addToast} = useToastContext()
  const environment = useRelayEnvironment()
  const {copilot_swe_agent, issues_react_assignee_picker_v2} = useFeatureFlags()

  const updateAssignees = useCallback(
    (selectedAssignees: Assignee[]) => {
      if (issues_react_assignee_picker_v2) {
        commitUpdateIssueAssigneesMutationV2({
          environment,
          input: {issueId, assignedActors: selectedAssignees, participants},
          onError: () =>
            // eslint-disable-next-line @github-ui/dotcom-primer/toast-migration
            addToast({
              type: 'error',
              message: ERRORS.couldNotUpdateAssignees,
            }),
          onCompleted: onIssueUpdate,
        })
      } else {
        const copilotAssignees = selectedAssignees.filter(a => isCopilot(a.login))
        if (copilot_swe_agent && copilotAssignees.length !== 0) {
          commitAssignAppToIssueMutation({
            environment,
            input: {
              issueId,
              appIds: copilotAssignees.map(a => a.id),
            },
            onError: () =>
              // eslint-disable-next-line @github-ui/dotcom-primer/toast-migration
              addToast({
                type: 'error',
                message: ERRORS.couldNotUpdateAssignees,
              }),
            onCompleted: onIssueUpdate,
          })
        } else {
          commitUpdateIssueAssigneesMutation({
            environment,
            input: {issueId, assignees: selectedAssignees, participants},
            onError: () =>
              // eslint-disable-next-line @github-ui/dotcom-primer/toast-migration
              addToast({
                type: 'error',
                message: ERRORS.couldNotUpdateAssignees,
              }),
            onCompleted: onIssueUpdate,
          })
        }
      }
    },
    [addToast, copilot_swe_agent, environment, issueId, issues_react_assignee_picker_v2, onIssueUpdate, participants],
  )

  const readonlyAssignee = !viewerCanAssign || isRepositoryArchived

  const assigneeSectionHeader = useMemo(() => {
    if (readonlyAssignee) {
      return <ReadonlyAssigneesSectionHeader />
    }

    const pickerProps = {
      repo,
      owner,
      number,
      anchorElement: (props: React.HTMLAttributes<HTMLElement>, ref: RefObject<HTMLButtonElement>) => (
        <SectionHeader title={LABELS.sectionTitles.assignees} buttonProps={props} ref={ref} />
      ),
      readonly: readonlyAssignee,
      shortcutEnabled: singleKeyShortcutsEnabled,
      assignees,
      suggestions: participants,
      onSelectionChange: (selectedAssignees: Assignee[]) => updateAssignees(selectedAssignees),
      maximumAssignees,
      insidePortal: insideSidePanel,
      assignableApps,
    }

    if (issues_react_assignee_picker_v2) {
      return <AssigneePickerV2 {...pickerProps} />
    } else {
      return <AssigneePicker {...pickerProps} />
    }
  }, [
    readonlyAssignee,
    repo,
    owner,
    number,
    singleKeyShortcutsEnabled,
    assignees,
    participants,
    maximumAssignees,
    insideSidePanel,
    assignableApps,
    updateAssignees,
  ])

  return (
    <AssigneesSection
      sectionHeader={assigneeSectionHeader}
      assignees={assignees}
      onSelfAssignClick={() => (viewerData ? updateAssignees([viewerData]) : noop)}
      readonly={readonlyAssignee}
    />
  )
}

try{ ReadonlyAssigneesSectionHeader.displayName ||= 'ReadonlyAssigneesSectionHeader' } catch {}
try{ AssigneesSection.displayName ||= 'AssigneesSection' } catch {}
try{ CreateIssueAssigneesSection.displayName ||= 'CreateIssueAssigneesSection' } catch {}
try{ EditIssueAssigneesSection.displayName ||= 'EditIssueAssigneesSection' } catch {}